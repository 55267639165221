import React from "react";
import { Link } from "react-router-dom";
import sl from '../images/rtec/sl.png';
import sl1 from '../images/rtec/sl1.png';
import styles from '../styles/Pages.module.css';
import MetaTags from 'react-meta-tags';


export default function SlSerija({imge}) {

    return(  <div data-aos="fadeIn"  >
           <MetaTags>
    <title> Motori za kapije | Motori za klizne kapije r-tec-SL serija   </title>
    <meta id="meta-description" name="description" content="Za kapije težine do 1800kg. Ilgomatika automatika, motori za kapije, motori za klizne kapije, r-tec SL serija" />
    <meta id="og-title" property="og:title" content="Motori za kapije | Motori za klizne kapije - r-tec-SL serija  " />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Za kapije težine do 1800kg. Ilgomatika automatika, motori za kapije, motori za klizne kapije, r-tec SL serija" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/r-tec-sl-serija" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>
 
<div style={{textAlign:'center',backgroundColor:'#e6e7e8',paddingBottom:'5%'}}>
        <div style={{marginLeft:'2%',textAlign:'left',fontSize:'larger'}} >

    <Link style={{textAlign:'left',marginLeft:'2%'}} to='/motori-za-klizne-kapije'><p style={{color:'#2e3c67'}}>🔙</p></Link></div>
    <h3>r-tec SL serija </h3>
    <h5 style={{margin:'0 5%'}}>ELEKTROMEHANIČKI  OPERATORI ZA KLIZNE KAPIJE </h5>   <br/>
    <div  style={{margin:'0 10%'}} className={styles.acdcontainer1}>
<div className={styles.abcd}>
    <img  className={styles.acd} src={sl} alt='Sample' />
    </div>
    <div className={styles.abcd}>
    <img  className={styles.acd}src={sl1} alt='Sample' />
    </div>
     </div>
    </div>
  
</div>
    )
}