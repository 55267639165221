import React,{ useState } from 'react';
import av from '../images/automatskavrata/avrata.jpg';
import av1 from '../images/automatskavrata/avrata1.jpg';
import vrata from '../images/automatskavrata/automatskavrata.png';
import vrata1 from '../images/automatskavrata/automatskavrata1.png'
import vrata5 from '../images/automatskavrata/automatskavrata5.png';
import vrata10 from '../images/automatskavrata/automatskavrata10.jpg';
import vrata8 from '../images/automatskavrata/automatskavrata8.jpg';
import vrata12 from '../images/automatskavrata/automatskavrata12.jpg';
import '../styles/Pages.module.css';
import MetaTags from 'react-meta-tags';
import styles from '../styles/Pages.module.css';


const vrataImageData = [
  { imgeVrata:vrata5 },
  { imgeVrata:vrata1 },
  { imgeVrata:vrata },
  { imgeVrata:vrata10 },
  { imgeVrata:vrata8 },
  { imgeVrata:vrata12 },
]

export default function Vrata({imge}) {
  const [isActive, setIsActive] = useState(false);
 const vrataAccordionData = [
  {
      titleVrata: 'TEHNIČKA SPECIFIKACIJA AUTOMATSKIH VRATA',
      pdfVrata: '/automatskavrata.pdf',
      contentVrata:'Tehničku specifikaciju automackih vrata mozete pogledati ovde '
    }

  ];
  
  return (  <div data-aos="fadeIn"  >  <MetaTags>
    <title> Automatska senzorska vrata | Parking rampe | Motori za kapije </title>
    <meta id="meta-description" name="description" content="Ilgomatika automatika, automatska senzorska vrata, teleskopska automatska vrata i kružna automatska vrata" />
    <meta id="og-title" property="og:title" content="Automatska senzorska vrata | Parking rampe | Motori za kapije " />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Ilgomatika automatika, automatska senzorska vrata, teleskopska automatska vrata i kružna automatska vrata" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/automatska-vrata" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>

     <div     className="pagecontainer">
     <div style={{padding:'2%'}} >
         
         <div className="pageimagediv1">
      {vrataImageData.map(({ imgeVrata,i}) => (
        <div  key={imgeVrata} className='bg-image hover-zoom' style={{textAlign:'center',margin:' 2%',backgroundColor:'none'}}>
  <img src={imgeVrata} className='img-fluid'   alt='Sample'  />
</div>
       ))}
     </div>
     </div> <div style={{padding:'2%'}}>  
<h3>AUTOMATSKA VRATA </h3>
<br/>
         <p>Naša automatska vrata brenda “r-tec” su idealno rešenje  za poslovne i stambene objekte.
U ponudi, pored osnovnih kliznih automatskih vrata imamo teleskopska i kružna automatska vrata.
Elegantnog dizajna i tihog rada pravi su izbor za ulaze sa velikom frekvencom prolaska.</p>

<p>Vrata pokreće elektromotor nemačkog proizvodjača Dunker, a rad kontroliše elektronika. Mehanizam za pokretanje, motor i elektronika su smešteni u aluminijumsku gredu za koju su zakačena krila vrata od aluminijumski profila i pamplex stakla 4+1+4 / 8mm. <br/>Po želji kupca krila vrata mogu biti od kaljenog stakla bez okvira.
 Aluminijumski profili su eloksirani ili plastificirani u ral po želji kupca.
</p>
<div className="columns">

<ul style={{marginRight:'5%'}}><b>
Komplet vrata sadrži:</b>
<li>
- gredu sa motorom, elektronikom <br/>i mehanizmom za pokretanje</li><li>
- spoljašnji i unutrašnji radar</li><li>
- bočne fotoćelije</li><li>
- krila sa staklom</li>
</ul>
<ul  ><b>
Dodatna oprema koja se može ugraditi:</b>
<li>- baterija </li><li>
- elekto ili mehanička brava</li><li>
- beskontaktni senzor za otvaranje,<br/> šifrator, čitač za kartice ili daljinska komanda</li>
<li>
- fotoćelijski radar</li>
</ul>
</div>
<br/> <br/>
         <div style={{marginBottom:'2%'}} >
        {vrataAccordionData.map(({ titleVrata, contentVrata,pdfVrata }) => (

<div   key={titleVrata}  style={{margin:'0%',display:'flex',justifyContent:'center'}}>
   <div style={{ width: '100%' ,textAlign:'center'}}>
    <div className="accordionitem">
      <div className="accordion" onClick={() => setIsActive(!isActive)}>
        <div style={{display:'flex', borderBottom:'1.5px solid lightgray',justifyContent:'space-between',padding:'0%'}} >
          <p className="accordiontext">{titleVrata}</p>
          <p className="titlesymbol">{isActive ?  '－':'＋' } </p></div>
   
      </div>
      {isActive && <div className={styles.accordioncontent1}  style={{backgroundColor:'white'}}   >
          <div className={styles.acdcontainer}  style={{backgroundColor:'white'}}><div className="abcd">
          <img src={av} className={styles.acd}   alt='Sample'  /></div>
          <div className="abcd" >
        <img src={av1}  className={styles.acd}   alt='Sample'  />
</div></div>
          
           </div>}
    </div>
  </div></div>
   
          ))}</div>
          </div>    
          </div>
          </div>)}