import React from 'react';

import { Link } from 'react-router-dom';
import styles from '../styles/Pages.module.css';
import sl from '../images/kliznekapije /sl.png';
import king from '../images/kliznekapije /king.png'
import kliznekapije1 from '../images/kliznekapije /kliznekapije1.png';
import kliznekapije4 from '../images/kliznekapije /kliznekapije4.png';
import kliznekapije5 from '../images/kliznekapije /kliznekapije5.png';
import MetaTags from 'react-meta-tags';



export default function KlizneKapije() {
  const klizneImageData = [
    { imgeKlizne:kliznekapije1 },
    { imgeKlizne:kliznekapije4 },
    { imgeKlizne:kliznekapije5 },
  ]

  return (  
    <div data-aos="fadeIn"  >
<MetaTags>
    <title>Motori za kapije | Motori za klizne kapije  </title>
    <meta id="meta-description" name="description" content="Ilgomatika automatika, motori za kapije, motori za klizne kapije, r-tec SL serija ,r-tec KING serija" />
    <meta id="og-title" property="og:title" content="  Motori za kapije | Motori za klizne kapije" />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Ilgomatika automatika, motori za kapije, motori za klizne kapije, r-tec SL serija ,r-tec KING serija" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/motori-za-klizne-kapije" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>
    <div  className={styles.pagecontainer1}>
  <div style={{padding:'2%'}} >  
<h3 style={{}}>MOTORI ZA KLIZNE KAPIJE </h3>
<br/>
<div  >
  <div style={{padding:'0%'}}  >
<p>
Ako želite da automatizujete vašu kapiju u ponudi imamo motore za klizne i krilne kapije brendova r-tec, Cardin, FACC i NICE.<br/>
Svi motori imaju elegantan dizajn, kvalitetno izradjeno kućište u koje su smešteni elektronika, elektromotor i mehanizam za pokretanje.
<br/>Motori rade na 24V, 220V i 380V, a namenjeni su za težinu kapija od 100kg do 4000kg.
<br/></p>
<ul >
Set jednog motora sadrži:<br/>
<b>
postolje motora,
motor,
par fotoćelija, signalnu lampu, dva daljinska upravljača, ključ za deblokadu motora, zupčastu letvu 
</b></ul>
<div  style={{margin:'4% 0'}}>
<h5  className={styles.la}>Brend r-tec nudimo u SL seriji i KING seriji.</h5>

<div className={styles.ccct}>

        <div className={styles.ccontainer}  >
        <img className={styles.ii}    alt='Sample' src={sl}/>    
            <Link className={styles.tt}  to='/r-tec-sl-serija'>
        < h4 className=' mb-0 text-center'style={{color:'#2e3c67'}}>
           r-tec  SL
            </h4>
            </Link>
  </div>
        <div className={styles.ccontainer}  >
    <img   alt='Sample'  className={styles.ii}    src={king}/>          
    <Link className={styles.tt}  to='/r-tec-king-serija'>
        < h4 className=' mb-0 text-center'style={{color:'#2e3c67'}}>
           r-tec  KING
            </h4>
            </Link>
    </div> 
</div>
</div>
<p>
Na svaki motor dobija se garancija 24 meseca kada naš tim vrši ugradnju motora i ostalih komponenti.<br/>
Na vama je samo da odaberete odgovarajući motor za vašu kapiju, a mi smo tu da vam pomognemo u odabiru.
</p>
<br/>
  </div> </div>
          </div>

<div style={{padding:'2%'}} >      
<div className={styles.pageimagediv1}>
{klizneImageData.map(({ imgeKlizne}) => (
  <div  key={imgeKlizne} className='bg-image hover-zoom' style={{textAlign:'center',margin:' 2%',backgroundColor:'none'}}>
  <img src={imgeKlizne} className='img-fluid'   alt='Sample' />

</div>
))}
</div>    </div>
        </div>
     </div>
          
   )}