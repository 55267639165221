import React,{ useState} from 'react';
import styles from '../styles/Pages.module.css';
import kk from '../images/krilnekapije/krilne.png'
import kk1 from '../images/krilnekapije/krilne1.png'
import krilnekapije1 from '../images/krilnekapije/krilnekapije1.png'
import krilnekapije2 from '../images/krilnekapije/krilnekapije2.png'
import krilnekapije3  from '../images/krilnekapije/krilnekapije3.png'
import krilnekapije5  from '../images/krilnekapije/krilnekapije5.jpg'
import MetaTags from 'react-meta-tags';

const krilneImageData = 
[ 
    { imgeKrilne:krilnekapije1 },
    { imgeKrilne:krilnekapije2 },
    { imgeKrilne:krilnekapije5 }, 
]


export default function KrilneKapije({imge}) {
  const [isActive, setIsActive] = useState(false);
 
  const krilneData = [
    {
      titleKrilne: 'TEHNIČKA SPECIFIKACIJA MOTORA ZA KRILNE KAPIJE',
    }
  ];
  
  return (     <div data-aos="fadeIn"  >

<MetaTags>
    <title> Motori za kapije | Motori za krilne kapije  </title>
    <meta id="meta-description" name="description" content="Ilgomatika automatika, motori za kapije, motori za krilne kapije, motori brenda r-tec i FACC" />
    <meta id="og-title" property="og:title" content=" Motori za kapije | Motori za krilne kapije" />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Ilgomatika automatika, motori za kapije, motori za krilne kapije, motori brenda r-tec i FACC" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/motori-za-krilne-kapije" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>
   
    <div    className={styles.pagecontainer}>
     <div style={{padding:'2%'}} >
         
         <div className={styles.pageimagediv1}>
      {krilneImageData.map(({ imgeKrilne,i}) => (
       <div key={imgeKrilne}  className='bg-image hover-zoom' style={{textAlign:'center',margin:' 2%',backgroundColor:'none'}}>
       <img src={imgeKrilne} className='img-fluid'   alt='Sample'    />
     </div>
       ))}
     </div>
     </div> <div style={{padding:'2%'}}>  
<h3>MOTORI ZA KRILNE KAPIJE </h3>
<br/>

         <p>Motori za krilne kapije se koriste za automatsko otvaranje jednokrilnih i dvokrilnih kapija. Izbor motora zavisi od težine i dužine krila kapije. Izuzetno bitan faktor u odabiru  motora je i uticaj vetra. Kapije čija su krila zatvorena (nema strujanja vetra kroz krila) trpe jak pritisak vetra koji utiče i na sam motor, pa se taj faktor mora uzeti u obzir prilikom odabira motora.
U ponudi imamo motore brenda r-tec i FACC.
<br/>Motori brenda r- tec su sa navojnim vretenom, a u ponudi imamo više modela koji su namenjeni za različite težine i dužine kapija.
</p><ul>

<b>Motori dolaze u setu koji sadrži:</b><li>
- kontrilnu jedinicu (centralu)</li><li>
- dva motora </li><li>
- dva daljinska upravljača</li><li>
- par fotoćelija </li><li>
- signalnu lampu</li><li>
- ključeve za deblokadu motora</li>

</ul>

<br/>
         <div>
        {krilneData.map(({ titleKrilne}) => (
     <div  key={titleKrilne} style={{margin:'0%',display:'flex',justifyContent:'center'}}>
     <div style={{ width: '100%' ,textAlign:'center'}}>
  
      <div className={styles.accordionitem}>
        <div className={styles.accordion} onClick={() => setIsActive(!isActive)}>
          <div style={{display:'flex', borderBottom:'1.5px solid lightgray',justifyContent:'space-between',padding:'0%'}} >
            <p className={styles.accordiontext}>{titleKrilne}</p>
            <p className={styles.titlesymbol}>{isActive ?  '－':'＋' } </p></div>
     
        </div>
        {isActive && <div className={styles.accordioncontent1}   >
          <div className={styles.acdcontainer}><div className="abcd">
          <img src={kk} className={styles.acd}   alt='Sample'  /></div>
          <div className="abcd" >
        <img src={kk1}  className={styles.acd}   alt='Sample'  />
</div></div>
          
           </div>}
      </div>
    </div></div>
          ))}</div>
          </div>

     
        </div>
     </div>
  
          
   )}