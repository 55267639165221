import React, { useState } from 'react';

import logo from '../images/logoresized1.png'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import {
  Link,
} from "react-router-dom";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };



  return (
  <div className='ncontainer'>

      <Navbar color="light"  light expand="md"   >
          <NavLink className='nlink'  >  <Link     to="/"> <img   style={{width:'100%'}}  alt='Sample'    src={logo}  /></Link> </NavLink>
        <NavbarToggler onClick={!toggle} > <span style={{color:'#2e3c67'}}>    </span> </NavbarToggler>
        <Collapse isOpen={!isOpen} navbar>
         
          <Nav className='nbar' navbar    style={{cursor:'pointer'}}  >
            <React.Fragment>
    
              <NavItem>
                <Link  to="/automatska-vrata">
                  <NavLink className='nbl' >AUTOMATSKA VRATA</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/motori-za-klizne-kapije">
                  <NavLink className='nbl' >MOTORI ZA KLIZNE KAPIJE </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/motori-za-krilne-kapije">
                  <NavLink className='nbl'>MOTORI ZA KRILNE KAPIJE</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/parking-rampe">
                  <NavLink className='nbl'>
                  PARKING RAMPE
                  </NavLink>
                </Link>
              </NavItem>  
              <NavItem>
                <Link to="/"  >
                  <NavLink className='nbl'>
               
                 GALERIJA
                  </NavLink>
                </Link>
              </NavItem>          <NavItem  >
                <Link     to="/ilgomatik">
                  <NavLink className='nbl'  >KO SMO MI? </NavLink>
                </Link>
              </NavItem>
            </React.Fragment>
          </Nav>
        
        </Collapse>
      </Navbar>
      </div>
  );
};

export default NavBar;