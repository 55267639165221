import React from 'react';
import styles from '../styles/Footer.module.css'
import { MDBIcon,MDBBtn,MDBFooter } from 'mdb-react-ui-kit';
import logoblack from '../images/logoblack1.png';
import {Link} from 'react-router-dom';
import logo from '../images/logoresized1.png';


const Footer = () => {
    return (

      <MDBFooter bgColor='light' light >
          <div className={styles.infocontainer}  >

<div className={styles.infodiv1} >
<img   alt='Sample'    src={logo}  />
<br/>
<p  className={styles.p1}>RADNO VREME</p>
<p  className={styles.p1}>PONEDELJAK-PETAK
  <br/>
 <b> 08:00 - 20:00</b>
</p>
</div> 

<div className={styles.infodiv}   >
<p>
<span> <MDBIcon  fas icon="map-marker-alt"   size='lg'/> {"  "}
 Beograd - Ralja
<br/>{ "   "}
Profesora Andonovića 6
  </span>
</p> 
<p>
<span> <MDBIcon fas icon="mobile-alt" size='lg'   /> {"  "}  064/14-69-733  </span>
 </p>
 <p   >

    <a  className='text-body'   href="ilgomatik.bg@gmail.com"  >
 <MDBIcon fas icon="envelope" /> {"  "} 
ilgomatik.bg@gmail.com
 </a>
</p>
</div> 

<div className={styles.infodiv}>
    <div style={{display:'block'}}> 
    <a   style={{color:'#2e3c67'}} href='https://www.facebook.com/ilgomatik'   target='_blank' rel="noreferrer"  >  
<span>
<MDBIcon fab icon="facebook-square"  size='2x'/>{"  "}ilgomatik</span>
</a>
<br/>
<a   style={{color:'#2e3c67'}} href='https://www.instagram.com/ilgomatik_automatika/'   target='_blank' rel="noreferrer"  >  
<span>
<MDBIcon   fab icon="instagram" size='2x' />{"  "}ilgomatik_automatika</span>
</a>
<br/>
<br/> <Link   to="/kontakt" >
<MDBBtn style={{backgroundColor:'#fc7c04'}}>
       KONTAKTIRAJTE NAS
      </MDBBtn></Link>
    </div> 
    <br/>   <Link  to='/uslovikoriscenja-i-politikaprivatnosti'><b  style={{fontSize:'x-small'}} className='text-body'>Uslovi korišćenja i
 Politika privatnosti</b></Link>
    </div>

    <div className={styles.infodiv}>

</div></div>
   
            <footer className={styles.footer}>
            ILOGmatik | sva prava zadržana | izrada
        <a    href='https://marijana-portfolio-website.web.app/' target='_blank' rel="noreferrer"     > 
      <img className={styles.lgo} alt='Sample'    src={logoblack}/>
       </a> 
      </footer>
        </MDBFooter>

    );
};

export default Footer;