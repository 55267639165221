import React from 'react';

import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import ModalContainer from './components/Modal';
import './App.css';
import Home from './pages/home';
import Footer from './components/Footer';
import ContactForm from './pages/kontakt';
import ParkingRampe from './pages/parking-rampe';
import KlizneKapije from './pages/motori-za-klizne-kapije';
import KrilneKapije from './pages/motori-za-krilne-kapije';
import Vrata from './pages/automatska-vrata';
import Poltiika from './pages/uslovikoriscenja-i-politikaprivatnosti';
import SlSerija from './pages/r-tec-sl-serija';
import KingSerija from './pages/r-tec-king-serija';
import Galerija from './pages/galerija';
import ScrollToTop from './ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NavBar from './components/Nbar';
import MetaTags from 'react-meta-tags';

AOS.init();



function App() {
  return (
   
        <Router>
  <ScrollToTop>
    <div className="App">
    <MetaTags>
    <title> Automatska vrata | Parking rampe | Motori za kapije </title>
    <meta id="meta-description" name="description" content="Ilgomatik automatika, automatska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
    <meta id="og-title" property="og:title" content=" Automatska vrata | Parking rampe | Motori za kapije " />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Ilgomatik automatika, automatska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>

    <NavBar/>
       
              <div>
            <Routes>
              <Route path="/" exact element={<Galerija/>} />
              <Route path="/kontakt"  element={<ContactForm/>} />
              <Route path="/parking-rampe" element={< ParkingRampe/>} />

              <Route path="/motori-za-klizne-kapije" exact element={<KlizneKapije/>} />
              <Route path="/motori-za-krilne-kapije" exact element={<KrilneKapije/>} />
              <Route path="/automatska-vrata" exact element={<Vrata/>} />
              <Route path="/uslovikoriscenja-i-politikaprivatnosti" exact element={<Poltiika/>} />
              <Route path="/r-tec-sl-serija" exact element={<SlSerija/>} />
              <Route path="/r-tec-king-serija" exact element={<KingSerija/>} />
              <Route path="/ilgomatik" exact element={<Home/>} />

            </Routes>
              </div>
        <Footer/>
        <ModalContainer/>
    </div></ScrollToTop>
        </Router>

   
  );
}

export default App;
