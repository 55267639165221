import React from 'react';
import styles from '../styles/Home.module.css'
import MetaTags from 'react-meta-tags';

const Poltiika = () => {

    return( <div data-aos="fadeIn"  >
     <MetaTags>
    <title> Automatska vrata | Parking rampe | Motori za kapije </title>
    <meta id="meta-description" name="description" content="Ilgomatik automatika, automatska senzorska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
    <meta id="og-title" property="og:title" content="Automatska vrata | Motori za kapije | Parking rampe " />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Ilgomatik automatika, automatska senzorska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/uslovikoriscenja-i-politikaprivatnosti" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>

        <div     className={styles.politika}>
            <h5>
                Uslovi korišćenja
            </h5>
          <p>
          Ovaj sajt je u vlasništvu Ilgomatika. Korišćenje sajta podleže pravilima i uslovima korišćenja. Molimo vas da se upoznate sa pravilima i uslovima korišćenja sajta, jer će se smatrati da ste korišćenjem sajta ista pročitali i prihvatili. Ukoliko ne prihvatate uslove i pravila korišćenja sajta, molimo vas da sajt ne koristite.
</p>
<p>
Ovaj sajt i njegovi delovi imaju informativnu svrhu, vlasnik sajta ne daje garancije za tačnost, primenljivost i kompletnost informacija i neće biti odgovoran za bilo koje greške ili propuste kada je reč o ovim informacijama. Sav sadržaj, slike i tekst na sajtu je u vlasništvu iglomatika ili je dobijena dozvola za njihovo korišćenje. 
Svako preuzimanje i umnožavanje sadržaja bez dozvole Ilgomatika je strogo zabranjeno.
          </p>
          <br/>
          <h5>
      Politika privatnosti
            </h5>
          <p>
          Web sajt traži vaše lične podatke samo ako želite da nas kontaktirate i samovoljno popunite forme na našem web sajtu,
           i iste koristimo isključivo kako bi smo odgovorili na vaš upit putem e-maila ili drugim putem ako ste naglasili u poruci.
       
           </p>    <p>
Prilikom posete našeg web sajt, naš web provajder automatski beleži podatke koju uključuju IP adresu vašeg računara, 
 vrstu pretraživača, vreme posete, informacije o stranicama koje posetite na našoj web lokaciji.
Ove podatke koristimo za statističke analize o korišćenju naših stranica, s ciljem da poboljšamo kvalitet usluge. Naš web provajder ne beleži vašu e-mail adresu ili bilo koje druge podatke koji bi se mogli iskoristiti za vašu ličnu identifikaciju ili kontaktiranje bez vašeg pristanka

          </p>
          <p>
       Naša web stranica takođe koristi Google Analytics za analizu korišćenja web stranice. Google Analytics upotrebljava kolačiće za prikupljanje standardnih podataka internetskih zapisnika i posetilaca ili podataka u anonimnom obliku. Informacije koje generira kolačić o vašoj upotrebi web stranice (uključujući IP adresu) prenose se Google-u. 
       Informacije se zatim koriste za procenu korišćenja web stranice posetioca i za prikupljanje statističkih izveštaja o aktivnostima na web sajtu.
          </p>    <br/>
        </div> </div>
    )
}

export default Poltiika;