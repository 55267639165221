import React from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { MDBInput} from 'mdb-react-ui-kit';
import styles from '../styles/Pages.module.css';
import * as emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import ModalDiv from '../components/AlertModal';
import MetaTags from 'react-meta-tags';

class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject:'',
     message: '',
      checked: true,
      successmessage:false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
  
    this.handleChange = this.handleChange.bind(this);
  }


  handleSubmit(event) {
    event.preventDefault();
   
    const { name, email, subject, message } = this.state;
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: '/*YOUR NAME OR COMPANY*/',
    from_subject:subject,
      message_html: message,
    };
    emailjs.send(
      'service_hs8ik2q',
      'template_kbrvvce',
       templateParams,
      'user_ROJahI7xwrBvkHfI0teqW'
    )
    this.resetForm();

  };

  resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
    checked:false,
      message: '',
      successmessage:true
    });
  }


  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });


  }

  render() {
    const { name, email,  message, subject ,successmessage}= this.state;
  return (
    <div data-aos="fadeIn"  >
   
   <MetaTags>
    <title> Automatska vrata | Parking rampe | Motori za kapije</title>
    <meta id="meta-description" name="description" content="Ilgomatik automatika, automatska senzorska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
    <meta id="og-title" property="og:title" content=" Automatska vrata | Parking rampe | Motori za kapije " />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Ilgomatik automatika, automatska senzorska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/kontakt" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>
  <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:''}}>

<form  onSubmit={this.handleSubmit  }   style={{margin:'2%  5%',paddingBottom:'2%', textTransform:'',minWidth:'38vw',padding:' 4% 5%'}} >
  
         <p  style={{textTransform:'uppercase'}}> Ukoliko želite ponudu ili imate neko pitanje za nas, možete nas kontaktirati ovde ...   </p>
     
     <br/>
           
  <MDBInput className=' focus-bg-danger'  label='* Ime i prezime '  id="typeText"  type='text' 
   name='name' value={name} onChange={this.handleChange}
    />
<br/>


  <MDBInput   label='* Email' id='typeEmail' type='email'      name="email" 
value={email}
onChange={this.handleChange}
/>


<br/>

<MDBInput  label='Naslov' id='typeText' type='text'      name="subject" 
value={subject}
onChange={this.handleChange}
/>

<br/>

< MDBInput  label='*Poruka' id='textAreaExample' textarea rows={7}   outline='success'   name="message" 
value={message}
onChange={this.handleChange}
 />

<div>
<input type='checkbox' onChange={() => {
        this.setState({checked: !this.state.checked});
        
      }}  
         defaultChecked={!this.state.checked} 
    /> <label> * Saglasan/Saglasna sam sa <Link to='/uslovikoriscenja-i-politikaprivatnosti'> <b style={{color:'#2e3c67'}}  >   Uslovima korišćenja i Politikom privatnosti</b></Link></label>
</div>
<br/>
  <p style={{textTransform:'lowercase',fontSize:'small',width:'100%',textAlign:'center'}}><b style={{fontSize:'large'}}> * </b>obavezni podaci</p>
<div className="d-grid  text-center col-4 mx-auto">
<button  className={styles.sendbutton}  text disabled={!this.state.email ||!this.state.name || !this.state.message ||  this.state.checked }  >
      POŠALJI
      </button> </div>
      <div className={styles.centered }>
{ successmessage===true ?(

<div  style={{textAlign:'center',color:'white',margin:'2%'}}>
 
  <ModalDiv/>
</div>
  )
  :( null)
}</div>
</form>
 </div></div>
  )
}
}
export default ContactForm;