import React,{ useState } from 'react';
import pr from '../images/parkingrampe/prampe.jpg';
import pr1 from '../images/parkingrampe/prampe1.jpg';
import parkingrampe from '../images/parkingrampe/parkingrampe.png';
import parkingrampe3 from '../images/parkingrampe/parkingrampe3.png';
import parkingrampe5 from '../images/parkingrampe/parkingrampe5.png';
import styles from '../styles/Pages.module.css';
import MetaTags from 'react-meta-tags';


const rampeImageData = [
  
  { imgeParking:parkingrampe3 },
  { imgeParking:parkingrampe },
    { imgeParking:parkingrampe5 },
  ]

export default function ParkingRampe({imge}) {
  const [isActive, setIsActive] = useState(false);
 const rampeData = [
    {
      titleRampe: 'TEHNIČKA SPECIFIKACIJA PARKING RAMPE',
     pdfRampe: '/parkingrampe.pdf',
     contentRampe:'Tehničku specifikaciju parking rampi mozete pogledati ovde '
    }
  ];
  
  return (   <div data-aos="fadeIn"  >
   <MetaTags>
    <title>Parking rampe | Motori za kapije | Automatska vrata  </title>
    <meta id="meta-description" name="description" content="Ilgomatika automatika, parking rampe, parking rampe brendova r-tec, Facc i Nice" />
    <meta id="og-title" property="og:title" content=" Parking rampe | Motori za kapije | Automatska vrata " />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Ilgomatika automatika, parking rampe, parking rampe brendova r-tec, Facc i Nice" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/parking-rampe" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>
   
    <div data-aos="fadeIn"  >
    <div  className={styles.pagecontainer}>
     <div style={{padding:'2%'}} >
            <div className={styles.pageimagediv1}>
      {rampeImageData.map(({ imgeParking,i}) => (
        <div key={imgeParking} className='bg-image hover-zoom' style={{textAlign:'center',margin:' 2%',backgroundColor:'none'}}>
        <img src={imgeParking} className='img-fluid'   alt='Sample'  
                      />
      </div>
       ))}
     </div>
     </div> <div style={{padding:'2%'}}>  
<h3>PARKING RAMPE </h3>
<br/>
         <p>Želite da zaštitite parking od neželjenog parkiranjas ispred vaše zgrade ili firme?
             <br/>Rešenje je ugradnja parking rampe. 
             </p><p>
 U ponudi imamo parking rampe brendova r-tec, Facc i Nice.
Rampe su sa dužinom ruke od 3m, 4m, 5m ili 6m.<br/>
U setu se isporučuje telo rampe, ruka, dva daljinska upravljača i dve foto ćelije.</p>
<ul><b>Kao dodatnu opremu možemo ugraditi:</b>
  <li> -induktivnu petlju za automatsko otvaranje </li>
  <li> -led svetla na ruci rampe</li>
  <li>-kontrolu ulaska pomoću čitača kartica ili taga</li>
  <li>- GSM kontrolu pristupa </li>
  <li>-kontrolu pristupa preko telefonske aplikacije.
</li></ul><p>
Rampe imaju svetlosnu signalizaciju na telu, a po želji se može ugraditi posebna signalna lampa.<br/>
Garancija na proizvod je 2 godine od dana ugradnje.
</p>
<br/>
         <div>
        {rampeData.map(({ titleRampe, contentRampe,pdfRampe }) => (
    <div  key={titleRampe} style={{margin:'0%',display:'flex',justifyContent:'center'}}>
    <div style={{ width: '100%' ,textAlign:'center'}}>
 
     <div className={styles.accordionitem}>
       <div className={styles.accordion} onClick={() => setIsActive(!isActive)}>
         <div style={{display:'flex', borderBottom:'1.5px solid lightgray',justifyContent:'space-between',padding:'0%'}} >
           <p className={styles.accordiontext}>{titleRampe}</p>
           <p className={styles.titlesymbol}>{isActive ?  '－':'＋' } </p></div>
       </div>
       {isActive && <div className={styles.accordioncontent1}   >
          <div className={styles.acdcontainer}><div className="abcd">
          <img src={pr} className={styles.acd}   alt='Sample'  /></div>
          <div className="abcd" >
        <img src={pr1}  className={styles.acd}   alt='Sample'  />
</div></div>
          
           </div>}
     </div>
   </div></div>
          ))}</div>
          </div>
        </div>
          </div>
          </div>)}