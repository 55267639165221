import React,{ useState, useEffect } from 'react'

import '../styles/Pages.module.css';

const ModalDiv = ({ variant, children }) => {
  const [show, setShow] = useState(true)

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)
    }, 5000)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }

  // If show is true this will be returned
  return (
    <div className='alrt'>
 <div style={{padding:'3% 4%'}} >

    <h5 style={{textAlign:'left'}}>
    Vaša poruka je uspešno prosleđena!
    </h5>
    <p style={{fontSize:'small',textAlign:'left'}}>
      Uskoro možete očekivati odgovor na vaš upit.
    </p>
<p style={{fontSize:'small',textAlign:'right'}}>ILGOmatik</p>

    </div>
 </div>
  )
}

ModalDiv.defaultPros = {
  variant: 'info',
}

export default ModalDiv;