import React from 'react';
import { Zoom } from 'react-slideshow-image';
import styles from '../styles/Home.module.css'
import 'react-slideshow-image/dist/styles.css';
import baner1 from '../images/baner4.png';
import baner2 from '../images/baner1.png';
import baner3 from '../images/baner3.png';



const Slideshow = () => {

const images = [baner1,baner3,baner2];

const zoomInProperties = {
    indicators: true,
    scale: 1,arrows:false
  }
  return (
    <div  className={styles.show} >
      <Zoom {...zoomInProperties}>
        {images.map((each, index) => (
          <div key={index} className={styles.slideimage}>
            <img   alt='Sample'   style={{ objectFit: "cover" ,width:'100%'}} src={each} />
          </div>
        ))}
      </Zoom>
    </div>
  )
}


export default Slideshow;
