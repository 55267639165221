
import React from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import About from '../components/About';
import Slideshow from '../components/SlideShow';
import MetaTags from 'react-meta-tags';


export default function Home() {
  return (
    <div data-aos="fadeIn"  >
<MetaTags>
    <title> Automatska vrata | Parking rampe | Motori za kapije  </title>
    <meta id="meta-description" name="description" content="Ilgomatik automatika, automatska senzorska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
    <meta id="og-title" property="og:title" content="  Automatska vrata | Parking rampe | Motori za kapije " />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Ilgomatik automatika, automatska senzorska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/ilgomatik" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>
<div>


<Slideshow/>

     <About/>
 
</div>

     </div>
  )
}
