import React from 'react';
import { Link } from 'react-router-dom';
import vrata from '../images/automatskavrata/automatskavrata.png';
import vrata1 from '../images/automatskavrata/automatskavrata1.png'
import vrata2 from '../images/automatskavrata/automatskavrata2.png'
import vrata3 from '../images/automatskavrata/automatskavrata3.png'
import vrata4 from '../images/automatskavrata/automatskavrata4.png';
import vrata5 from '../images/automatskavrata/automatskavrata5.png';
import vrata6 from '../images/automatskavrata/automatskavrata6.png';
import vrata7 from '../images/automatskavrata/automatskavrata7.png';
import vrata8 from '../images/automatskavrata/automatskavrata8.jpg';
import vrata9 from '../images/automatskavrata/automatskavrata9.jpg';
import vrata10 from '../images/automatskavrata/automatskavrata10.jpg';
import vrata11 from '../images/automatskavrata/automatskavrata11.jpg';
import vrata12 from '../images/automatskavrata/automatskavrata12.jpg';
import parkingrampe from '../images/parkingrampe/parkingrampe.png';
import parkingrampe2 from '../images/parkingrampe/parkingrampe2.png';
import parkingrampe3 from '../images/parkingrampe/parkingrampe3.png';
import parkingrampe4 from '../images/parkingrampe/parkingrampe4.png';
import parkingrampe5 from '../images/parkingrampe/parkingrampe5.png';
import parkingrampe7 from '../images/parkingrampe/parkingrampe7.png';
import parkingrampe9 from '../images/parkingrampe/parkingrampe7.jpg';
import parkingrampe8 from '../images/parkingrampe/parkingrampe8.jpg';
import kliznekapije1 from '../images/kliznekapije /kliznekapije1.png';
import kliznekapije2 from '../images/kliznekapije /kliznekapije2.png';
import kliznekapije3 from '../images/kliznekapije /kliznekapije3.png';
import kliznekapije4 from '../images/kliznekapije /kliznekapije4.png';
import kliznekapije5 from '../images/kliznekapije /kliznekapije5.png';
import krilnekapije from '../images/krilnekapije/krilnekapije.png';
import krilnekapije1 from '../images/krilnekapije/krilnekapije1.png';
import krilnekapije2 from '../images/krilnekapije/krilnekapije2.png';
import krilnekapije3  from '../images/krilnekapije/krilnekapije3.png';
import krilnekapije4 from '../images/krilnekapije/krilnekapije4.png';
import krilnekapije5 from '../images/krilnekapije/krilnekapije5.jpg';
import krilnekapije6 from '../images/krilnekapije/krilnekapije6.jpg';
import styles from '../styles/Pages.module.css';
import MetaTags from 'react-meta-tags';


const ImageData = [

  { text:'AUTOMATSKA VRATA',
  imge:vrata8,
  linkto:'/automatska-vrata' },
    { text:'PARKING RAMPE',
  imge:parkingrampe8  ,
  linkto:'/parking-rampe'},
  
  { text:'MOTORI ZA KRILNE KAPIJE',
  imge:krilnekapije4,
  linkto:'/motori-za-krilne-kapije' },

    { text:'MOTORI ZA KLIZNE KAPIJE',
    imge:kliznekapije1,
    linkto:'/motori-za-klizne-kapije' },
    { text:'AUTOMATSKA VRATA',
    imge:vrata10,
    linkto:'/automatska-vrata' },
 
  { text:'PARKING RAMPE',
    imge:parkingrampe  ,
    linkto:'/parking-rampe'},
   
    {   text:'AUTOMATSKA VRATA',
    imge:vrata11 ,
    linkto:'/automatska-vrata'},
    
    { text:'MOTORI ZA KLIZNE KAPIJE',
    imge:kliznekapije3 ,
    linkto:'/motori-za-klizne-kapije'},
    {   text:'AUTOMATSKA VRATA',
    imge:vrata12 ,
    linkto:'/automatska-vrata'},

    { text:'MOTORI ZA KRILNE KAPIJE',
    imge:krilnekapije5 ,
    linkto:'/motori-za-krilne-kapije'},

    { text:'PARKING RAMPE',
    imge:parkingrampe7 ,
    linkto:'/parking-rampe'},
    { text:'AUTOMATSKA VRATA',
    imge:vrata2,
    linkto:'/automatska-vrata' },
   
    { text:'MOTORI ZA KRILNE KAPIJE',
    imge:krilnekapije1 ,
    linkto:'/motori-za-krilne-kapije'},
   
    { text:'PARKING RAMPE',
    imge:parkingrampe9 ,
    linkto:'/parking-rampe'},

  {   text:'AUTOMATSKA VRATA',
  imge:vrata3 ,
  linkto:'/automatska-vrata'},

  {   text:'AUTOMATSKA VRATA',
    imge:vrata4 ,
    linkto:'/automatska-vrata'},

    { text:'MOTORI ZA KRILNE KAPIJE',
    imge:krilnekapije ,
    linkto:'/motori-za-krilne-kapije'},
  

  {   text:'AUTOMATSKA VRATA',
    imge:vrata6 ,
    linkto:'/automatska-vrata'},
   
    {text:'MOTORI ZA KLIZNE KAPIJE',
    imge:kliznekapije4 ,
   linkto:'/motori-za-klizne-kapije'},
  
   {   text:'AUTOMATSKA VRATA',
    imge:vrata7 ,
    linkto:'/automatska-vrata'},


    { text:'MOTORI ZA KLIZNE KAPIJE',
    imge:kliznekapije2 ,
    linkto:'/motori-za-klizne-kapije'},

  { text:'PARKING RAMPE',
    imge:parkingrampe2   ,
    linkto:'/parking-rampe'},
  
    { text:'PARKING RAMPE',
    imge:parkingrampe3 ,
    linkto:'/parking-rampe'},
   
    {   text:'AUTOMATSKA VRATA',
    imge:vrata5,
    linkto:'/automatska-vrata' },
    { text:'MOTORI ZA KRILNE KAPIJE',
    imge:krilnekapije6 ,
    linkto:'/motori-za-krilne-kapije'},
    {text:'PARKING RAMPE',
     imge:parkingrampe4 ,
     linkto:'/parking-rampe'},
  
    
    {  text:'AUTOMATSKA VRATA',
    imge:vrata ,
    linkto:'/automatska-vrata'},
    { text:'MOTORI ZA KRILNE KAPIJE',
    imge:krilnekapije2,
  
    linkto:'/motori-za-krilne-kapije' },
    
    {   text:'AUTOMATSKA VRATA',
    imge:vrata1,
  linkto:'/automatska-vrata' },

    
    { text:'GARAŽNA ROLO VRATA',
    imge:vrata9  ,
    linkto:'/'},
  
  
    { text:'MOTORI ZA KLIZNE KAPIJE',
    imge:kliznekapije5 ,
    linkto:'/motori-za-klizne-kapije'},
    
  
    { text:'MOTORI ZA KRILNE KAPIJE',
      imge:krilnekapije3 ,
      linkto:'/motori-za-krilne-kapije'},
     
      { text:'PARKING RAMPE',
      imge:parkingrampe5 ,
      linkto:'/parking-rampe'},
 
  ]

const Galerija =() => {
	return( <div data-aos="fadeIn"  >

<MetaTags>
    <title> Automatska vrata | Parking rampe | Motori za kapije </title>
    <meta id="meta-description" name="description" content="Ilgomatik automatika, automatska senzorska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
    <meta id="og-title" property="og:title" content="  Automatska vrata | Parking rampe | Motori za kapije " />
    <meta id="og-image" property="og:image" content="logo.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="logo.jpg" />
    <meta property="og:description" content="Ilgomatik automatika, automatska senzorska vrata, motori za kapije, motori za klizne kapije, motori za krilne kapije, parking rampe" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://ilgomatik.com/" />
            <meta property="og:site_name" content="Ilgomatik" />

  </MetaTags>
  <div>

    <div     style={{textAlign:'center',margin:'4%',marginTop:'3%'}}>
      <div    className={styles.vbg}>
      <video autoPlay loop controls className={styles.videodiv} >
        <source src="/video1.mp4" />
      </video>
      </div>
    </div>


      <div style={{margin:'4%',marginTop:'5%'}} >   
      <h2 style={{textAlign:'center',margin:'1%',letterSpacing:'10px'}}>GALERIJA</h2>

         <div className={styles.imagediv}  >
{ImageData.map(({ linkto,text,imge,i}) => (
  
  
  <div key={imge}  >
<div style={{position:'relative',margin:'2%' }}  className=' bg-image  hover-zoom'  >
<>

<img className={styles.imge}    src={imge} alt="Snow"  />
<div className={styles.bottomleft} > <Link to={linkto}><b style={{color:'white'}}   >{text}</b></Link></div>

</>
</div>
</div>


))}</div>
       </div>

</div>
</div>
  );
}

export default Galerija;