 import {Link} from 'react-router-dom';
import { useState } from "react";

function ModalContainer() {
    const [show, toggleShow] =useState(true);

    return (
      <div style={{position:'fixed',bottom:'0',textAlign:'center',width:'100%',padding:'0%',backgroundColor:'rgb(37, 37, 39,.8)',zIndex:'1',display:'flex',justifyContent:'center',color:'lightgray'}}>  {show && <div style={{textAlign:'center',alignItems:'center'}}>
           <div style={{textAlign:'right',justifyContent:'flex-end',position:'fixed',right:'0',paddingRight:'1%',cursor:'pointer',fontSize:'large'}} 
          onClick={() => toggleShow(!show)}
        >
 x
        </div> 
        <div style={{textAlign:'center',padding:' 3.5% 0%'}} >
            Korišćenjem ovog web sajta slazete se sa <Link to='/uslovikoriscenja-i-politikaprivatnosti'><b style={{cursor:'pointer',color:'white'}}>Uslovima koriscenja i Politikom privatnosti</b></Link>
        </div>
       
       </div>}
      </div>
    )
  }
  
 export default ModalContainer;