
import React from 'react';
import  styles from '../styles/Home.module.css'

const About = () => {
    return (
        <React.Fragment >
          <div className={styles.textabout}>
      <p >
      Osnovani smo davne 2009. godine kao malo porodično preduzeće koje se bavilo gradjevinskom delatnošću iz oblasti završnih radova u gradjevinarstvu. Tada smo poslovali pod nazivom Glet Plus.
Kako smo napredovali kroz poslovanje, proširili smo delatnost na izradu i ugradnju aluminijumske i PVC stolarije, rolo garažnih vrata, roletni i komarnika.
Kroz godine rada, motiv za napredovanje u poslovanju davao nam je veliki broj zadovoljnih klijenata kvalitetom proizvoda i pruženim uslugama.
U cilju da našim klijentima ponudimo još veći asortiman proizvoda koji će im modernizovati stambeni ili poslovni prostor, u ponudu već postojećih proizvoda dodajemo automatska senzorska vrata, parking rampe, kao i motore za klizne i krilne kapije.
      </p>
      
      <p>Naziv firme menjamo 2019. godine u <b>ILGOmatik </b>, kada postajemo ovlašćeni distributer i serviser za Republiku Srbiju kompanije ALKUR r-tec.
<br/>Od tada u ponudi imamo:
r-tec automatska senzorska vrata, 
r-tec parking rampe,
r-tec motore za klizne kapije,
r-tec motore za krilne kapije.
<br/>
Na ideju smo došli da našim klijentima obezbedimo kompletano rešenje za ogradjivanje dvorišta, pa smo u ponudu uvrstili i izradu ograda i kapija od aluminijuma koju možemo automatizovati jednim od naših motora. 
      </p>
      <p>
      Za nas, najveći uspeh je zadovoljstvo naših klijenata.
      </p>
      </div>
        </React.Fragment>
    );
};

export default About;